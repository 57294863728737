import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box, Typography, List, ListItem, ListItemText, ListItemAvatar,
  Avatar, Chip, Dialog, DialogContent, DialogTitle, IconButton,
  Switch, FormControlLabel, Grid, Paper, Badge, CircularProgress,
  Alert, Snackbar, Tooltip, Pagination, Fade, TextField, InputAdornment
} from '@mui/material';
import { MessageCircle, X as CloseIcon, Mail, RefreshCw, Filter, Search, Person, Share2, ExternalLink, User, UserCircle, UserRound, PersonStanding, MessageSquare, Phone, MessagesSquare, Globe, Building2 } from 'lucide-react';
import ChatFiltersSection from './ChatFiltersSection';
import { useAuth } from '../contexts/AuthContext';
import ChatHistoryDetail from './ChatHistoryDetail';
import { useNavigate } from 'react-router-dom';

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL || 'wss://admin.aitomotivelab.com';

const truncateText = (text, maxLength = 30) => {
  if (!text) return '';
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};

const getPlatformConfig = (platform) => {
  const configs = {
    api: {
      label: 'webchat',
      icon: MessageSquare,
      color: '#2196F3', // blue
      bgColor: 'rgba(33, 150, 243, 0.1)'
    },
    whatsapp: {
      label: 'whatsapp',
      icon: MessagesSquare,
      color: '#25D366', // whatsapp green
      bgColor: 'rgba(37, 211, 102, 0.1)'
    },
    voice: {
      label: 'voice',
      icon: Phone,
      color: '#FF5722', // deep orange
      bgColor: 'rgba(255, 87, 34, 0.1)'
    },
    default: {
      label: 'other',
      icon: Globe,
      color: '#9E9E9E', // grey
      bgColor: 'rgba(158, 158, 158, 0.1)'
    }
  };
  return configs[platform] || configs.default;
};

const ControllerChip = ({ controller, isCurrentUser, humanControl }) => {
  const theme = useTheme();
  
  if (!humanControl) return null;

  return (
    <Chip
      size="small"
      icon={<UserRound size={14} />}
      label={controller ? 
        `Controlled by ${isCurrentUser ? 'you' : controller.first_name}` : 
        'No controller'
      }
      sx={{
        mr: 1,
        mb: 1,
        backgroundColor: isCurrentUser 
          ? theme.palette.primary.main
          : controller 
            ? theme.palette.warning.main  // Increased contrast for other controllers
            : theme.palette.grey[300],    // No controller state
        color: isCurrentUser || controller
          ? '#fff'  // White text for better contrast
          : theme.palette.grey[800],
        fontWeight: 'medium',
        '& .MuiChip-icon': {
          color: 'inherit'
        }
      }}
    />
  );
};

const Chats = ({ onChatClick, client = null, initialChatId = null }) => {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authError, setAuthError] = useState(false);
  const [selectedChatId, setSelectedChatId] = useState(initialChatId);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize] = useState(20);
  const [refreshing, setRefreshing] = useState(false);
  const wsRef = useRef(null);
  const reconnectionAttempts = useRef(0);
  const isManuallyClosed = useRef(false);
  const [isPageChanging, setIsPageChanging] = useState(false);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [processingChats, setProcessingChats] = useState(new Set());
  const isMounted = useRef(true);

  const [filters, setFilters] = useState({
    search: '',
    is_connected: null,
    human_control: null,
    date_range: [null, null],
    show_only_lead: null,
    client_id: client?.id || null,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { token = localStorage.getItem('token'), userRole } = useAuth();
  const isAdmin = userRole === 'admin' || userRole === 'superadmin';
  
  console.log('Current userRole:', userRole);
  console.log('isAdmin:', isAdmin);

  const activeFiltersCount = useMemo(() => {
    let count = 0;
    if (filters.is_connected !== null) count++;
    if (filters.human_control !== null) count++;
    if (filters.date_range[0] || filters.date_range[1]) count++;
    if (filters.search) count++;
    return count;
  }, [filters]);

  const updateChat = useCallback((updatedChat) => {
    setChats(prevChats => {
      const chatId = updatedChat.chat?.id || updatedChat.id;
      const chatIndex = prevChats.findIndex(chat => 
        Number(chat.id) === Number(chatId)
      );

      if (chatIndex > -1) {
        const updatedChats = [...prevChats];
        updatedChats[chatIndex] = {
          ...updatedChats[chatIndex],
          ...(updatedChat.is_processing !== undefined && { is_processing: updatedChat.is_processing }),
          ...(updatedChat.sender_message && { last_message: updatedChat.sender_message }),
          ...(updatedChat.bot_message && { last_message: updatedChat.bot_message }),
          ...(updatedChat.chat?.client_name && {
            client__first_name: updatedChat.chat.client_name.split(' ')[0],
            client__last_name: updatedChat.chat.client_name.split(' ').slice(1).join(' '),
          }),
          ...(updatedChat.chat?.unique_id && { unique_id: updatedChat.chat.unique_id }),
          ...(updatedChat.chat?.ai_name && { ai_name: updatedChat.chat.ai_name }),
          ...(updatedChat.chat?.summary && { summary: updatedChat.chat.summary }),
          ...(updatedChat.human_control !== undefined && { 
            human_control: updatedChat.human_control,
            controller: updatedChat.controller || null,
            is_current_user_controller: updatedChat.is_current_user || false
          }),
          updated_at: updatedChat.updated_at || new Date().toISOString(),
          ...(updatedChat.human_control === undefined && { is_new: true })
        };
        return updatedChats;
      }

      if (updatedChat.sender_message || updatedChat.bot_message) {
        console.error('Attempted to update message for non-existent chat:', chatId);
        return prevChats;
      }

      return [{
        id: chatId,
        client__first_name: updatedChat.chat?.client_name ? updatedChat.chat.client_name.split(' ')[0] : '',
        client__last_name: updatedChat.chat?.client_name ? updatedChat.chat.client_name.split(' ').slice(1).join(' ') : '',
        unique_id: updatedChat.chat?.unique_id || '',
        ai_name: updatedChat.chat?.ai_name || '',
        summary: updatedChat.chat?.summary || '',
        last_message: updatedChat.bot_message || updatedChat.sender_message || '',
        updated_at: updatedChat.updated_at || new Date().toISOString(),
        is_new: true
      }, ...prevChats];
    });
  }, []);

  const updateChatWithNewMessage = useCallback((messageData) => {
    const chatId = messageData.chat_id;
    setChats(prevChats => {
      const chatIndex = prevChats.findIndex(chat => Number(chat.id) === Number(chatId));
      if (chatIndex > -1) {
        const updatedChats = [...prevChats];
        updatedChats[chatIndex] = {
          ...updatedChats[chatIndex],
          updated_at: new Date().toISOString(),
          last_message: messageData.bot_message || messageData.sender_message || messageData.admin_message,
          is_new: true
        };
        return updatedChats;
      }
      return prevChats;
    });
  }, []);

  const updateConnectionStatus = useCallback((data) => {
    setChats(prevChats =>
      prevChats.map(chat =>
        Number(chat.id) === Number(data.chat_id)
          ? {
            ...chat,
            is_connected: data.is_connected,
            last_connected: data.last_connected,
            last_disconnected: data.last_disconnected
          }
          : chat
      )
    );
  }, []);

  const deduplicateChats = useCallback((chatArray) => {
    const seen = new Map();
    return chatArray.filter(chat => {
      const duplicate = seen.has(chat.id);
      seen.set(chat.id, true);
      return !duplicate;
    });
  }, []);

  const handleWebSocketMessage = useCallback((data) => {
    console.log('WebSocket message received:', data);
    const action = data.action || data.type;

    switch (action) {
      case 'get_all_chats':
        if (data.error) {
          console.error('Error fetching chats:', data.error);
          setError(data.error);
          setChats([]);
        } else {
          console.log('Received chats data:', data.data);
          const processedChats = (data.data || []).map(chat => ({
            ...chat,
            client__first_name: chat.client__first_name || '',
            client__last_name: chat.client__last_name || '',
            ai__name: chat.ai__name || '',
            company__name: chat.company__name || '',
            human_control: chat.human_control || false,
            controller: chat.controller || null,
            is_current_user_controller: chat.controller?.is_current_user || false,
            is_connected: chat.is_connected || false,
            platform: chat.platform || 'default',
            pre_human_control: chat.pre_human_control || false,
            is_new: chat.is_new || false
          }));
          const uniqueChats = deduplicateChats(processedChats);
          setChats(uniqueChats);
          setTotalPages(data.pagination?.total_pages || 1);
          setCurrentPage(data.pagination?.current_page || 1);
        }
        setLoading(false);
        setRefreshing(false);
        setIsPageChanging(false);
        break;
      case 'set_human_control':
      case 'update_chat':
        console.log("update_chat", data)
        updateChat(data.data);
        break;
      case 'new_message':
      case 'message_complete':
      case 'admin_message':
        updateChatWithNewMessage(data.data);
        break;
      case 'update_connection_status':
        updateConnectionStatus(data.data);
        break;
      case 'chat_joined':
        if (!client) {
          setChats(prevChats => {
            const newChat = data.data;
            const existingChatIndex = prevChats.findIndex(chat => Number(chat.id) === Number(newChat.id));

            if (existingChatIndex > -1) {
              const updatedChats = [...prevChats];
              updatedChats[existingChatIndex] = {
                ...updatedChats[existingChatIndex],
                ...newChat,
              };
              return updatedChats;
            } else {
              return [newChat, ...prevChats];
            }
          });
        }

        break;
      case 'processing_status':
        const { chat_id, is_processing } = data.data;
        console.log('Processing status update:', { chat_id, is_processing });
        
        setProcessingChats(prev => {
          const newSet = new Set(prev);
          if (is_processing) {
            newSet.add(chat_id);
          } else {
            newSet.delete(chat_id);
          }
          return newSet;
        });
        
        setChats(prevChats => {
          // First try exact match
          const exactMatch = prevChats.some(chat => chat.unique_id === chat_id);
          console.log('Exact match found?', exactMatch, 'for unique_id:', chat_id);
          
          if (exactMatch) {
            return prevChats.map(chat => 
              chat.unique_id === chat_id
                ? { ...chat, is_processing: is_processing }
                : chat
            );
          }
          
          // Fall back to includes() if no exact match found
          const includesMatch = prevChats.some(chat => chat.unique_id?.includes(chat_id));
          console.log('Includes match found?', includesMatch, 'for unique_id:', chat_id);
          
          return prevChats.map(chat => 
            chat.unique_id?.includes(chat_id)
              ? { ...chat, is_processing: is_processing }
              : chat
          );
        });
        break;
      case 'predict_message':
        if (data.data?.predicted_message) {
          updateChat({
            chat: { id: data.data.chat_id },
            predicted_message: data.data.predicted_message
          });
        }
        break;
      case 'human_control_update':
        setChats(prevChats => 
          prevChats.map(chat => 
            chat.unique_id?.includes(data.data.chat_id)
              ? {
                  ...chat,
                  human_control: data.data.human_control,
                  controller: data.data.controller,
                  is_current_user_controller: data.data.is_current_user
                }
              : chat
          )
        );
        break;
      default:
        console.log('Unhandled WebSocket message:', data);
    }
  }, [filters, updateChat, updateChatWithNewMessage, updateConnectionStatus, client, deduplicateChats]);

  const connectWebSocket = useCallback(() => {
    if (!token) {
      setError('Authentication token is missing. Please log in again.');
      setLoading(false);
      return;
    }

    try {
      wsRef.current = new WebSocket(`${WSS_BASE_URL}/ws/admin_chat/?token=${token}`);
      
      wsRef.current.onopen = () => {
        console.log('WebSocket Connected');
        setError(null);
        setAuthError(false);
        reconnectionAttempts.current = 0;
        // Add slight delay before fetching to ensure connection is stable
        setTimeout(fetchChats, 500);
      };

      wsRef.current.onmessage = (event) => {
        let data;
        try {
          data = JSON.parse(event.data);
          console.log('Received data:', data);
        } catch (e) {
          console.error('Failed to parse message', e);
          return;
        }
        if (data.type === 'error' && data.message === 'Authentication required.') {
          setAuthError(true);
          wsRef.current.close();
        } else {
          if (data.type === 'chat.update' && !data.action) {
            data.action = 'update_chat';
          }
          handleWebSocketMessage(data);
        }
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket Error:', error);
        setError('Failed to connect to the chat server. Please try again later.');
        setLoading(false);
      };

      wsRef.current.onclose = (event) => {
        console.log('WebSocket Disconnected:', event.code, event.reason);
        if (!authError && !isManuallyClosed.current) {
          reconnectionAttempts.current += 1;
          const delay = Math.min(10000, 1000 * Math.pow(2, reconnectionAttempts.current));
          setTimeout(() => connectWebSocket(), delay);
        }
      };
    } catch (error) {
      console.error('Failed to create WebSocket:', error);
      setError('Failed to establish connection. Please refresh the page.');
      setLoading(false);
    }
  }, [token, handleWebSocketMessage, authError]);

  const fetchChats = useCallback(() => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      const filtersToSend = {};
      
      // Only include filters that have actual values
      if (filters.search) filtersToSend.search = filters.search;
      if (filters.is_connected !== null) filtersToSend.is_connected = filters.is_connected;
      if (filters.human_control !== null) filtersToSend.human_control = filters.human_control;
      if (filters.client_id) filtersToSend.client_id = filters.client_id;
      if (filters.show_only_lead !== null) filtersToSend.show_only_lead = filters.show_only_lead;
      if (filters.show_only_intervention !== null) filtersToSend.show_only_intervention = filters.show_only_intervention;
      
      if (filters.date_range?.[0] && filters.date_range?.[1]) {
        filtersToSend.date_range = [
          filters.date_range[0].toISOString(),
          filters.date_range[1].toISOString(),
        ];
      }

      const message = {
        action: 'get_all_chats',
        filters: filtersToSend,
        page: currentPage,
        page_size: pageSize
      };

      console.log('Fetching chats with message:', message);
      wsRef.current.send(JSON.stringify(message));
    } else {
      console.error('WebSocket connection state:', wsRef.current?.readyState);
      setError('Connection to server lost. Attempting to reconnect...');
    }
  }, [filters, currentPage, pageSize]);

  useEffect(() => {
    if (client && isMounted.current) {
      setFilters(prevFilters => ({
        ...prevFilters,
        client_id: client.id
      }));
    }
  }, [client]);

  useEffect(() => {
    fetchChats();
  }, [filters, currentPage, fetchChats]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      isManuallyClosed.current = true;
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [connectWebSocket]);

  useEffect(() => {
    console.log('Current state:', {
      wsState: wsRef.current?.readyState,
      loading,
      chatsCount: chats.length,
      filters,
      currentPage,
      totalPages,
      error
    });
  }, [loading, chats, filters, currentPage, totalPages, error]);

  useEffect(() => {
    if (initialChatId) {
      setSelectedChatId(initialChatId);
    }
  }, [initialChatId]);

  const handleChatClick = (chatId) => {
    setSelectedChatId(chatId);
    if (onChatClick) {
      onChatClick(chatId);
    }
    // Remove 'is_new' flag when chat is clicked
    setChats(prevChats =>
      prevChats.map(chat =>
        Number(chat.id) === Number(chatId) ? { ...chat, is_new: false } : chat
      )
    );
  };

  const handleHumanControlToggle = useCallback((event, chatId) => {
    event.stopPropagation();
    
    // Check if this chat is currently selected/opened
    if (chatId !== selectedChatId) {
      setError(
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <MessageCircle size={20} />
          Please click on the chat first to take control
        </Box>
      );
      // Add a subtle shake animation to the switch
      const switchElement = event.target.closest('.MuiSwitch-root');
      if (switchElement) {
        switchElement.style.animation = 'shake 0.5s cubic-bezier(.36,.07,.19,.97) both';
        setTimeout(() => {
          switchElement.style.animation = '';
        }, 500);
      }
      return;
    }

    // For non-admins: only allow if they are the current controller
    const chat = chats.find(c => Number(c.id) === Number(chatId));
    if (!isAdmin && chat?.human_control && !chat?.is_current_user_controller) {
      return; // Exit if not admin and trying to modify someone else's control
    }

    const newValue = event.target.checked;
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      // Add a pulsing effect to the chat item when toggling
      const chatItem = event.target.closest('.MuiListItem-root');
      if (chatItem) {
        chatItem.style.animation = 'pulse 0.5s ease';
        setTimeout(() => {
          chatItem.style.animation = '';
        }, 500);
      }

      wsRef.current.send(JSON.stringify({
        action: 'set_human_control',
        pk: chatId,
        human_control: newValue
      }));
    } else {
      setError('Unable to update human control. Please check your connection.');
    }
  }, [chats, isAdmin, selectedChatId, setError]);

  const handleCloseError = () => {
    setError(null);
  };

  const handlePageChange = (event, value) => {
    if (value === currentPage) return;
    setCurrentPage(value);
  };

  const handleFilterChange = (field, value) => {
    console.log('Filter change:', field, value);
    if (field === 'page') {
      setCurrentPage(value);
    } else {
      setFilters(prev => {
        const newFilters = {
          ...prev,
          [field]: value
        };
        console.log('New filters:', newFilters);
        return newFilters;
      });
      // Only reset page when actual filters change, not for page changes
      if (field !== 'page') {
        setCurrentPage(1);
      }
    }
  };

  const handleRefresh = () => {
    setRefreshing(true);
    // Reset filters when refreshing
    setFilters({
      search: '',
      is_connected: null,
      human_control: null,
      date_range: [null, null],
      show_only_lead: null,
      client_id: client?.id || null,
    });
    setCurrentPage(1);
    fetchChats();
  };

  const handleShareClick = (event, uniqueId) => {
    event.stopPropagation();
    const shareUrl = `http://app.aitomotivelab.com/openchat/${uniqueId}`;
    navigator.clipboard.writeText(shareUrl);
    // You might want to add a notification here to show the link was copied
  };

  const handleOpenShareLink = (event, uniqueId) => {
    event.stopPropagation();
    const shareUrl = `http://app.aitomotivelab.com/openchat/${uniqueId}`;
    window.open(shareUrl, '_blank');
  };

  const handlePredictMessage = useCallback((chatId) => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      console.log('Sending predict message request for chat:', chatId);
      wsRef.current.send(JSON.stringify({
        action: 'predict_message',
        chat_id: chatId
      }));
    } else {
      setError('Connection to server lost. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Add these styles to your component's return JSX, inside the Box component at the root level
  const styles = {
    '@keyframes shake': {
      '10%, 90%': {
        transform: 'translate3d(-1px, 0, 0)',
      },
      '20%, 80%': {
        transform: 'translate3d(2px, 0, 0)',
      },
      '30%, 50%, 70%': {
        transform: 'translate3d(-4px, 0, 0)',
      },
      '40%, 60%': {
        transform: 'translate3d(4px, 0, 0)',
      },
    },
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)',
      },
      '70%': {
        transform: 'scale(1.005)',
        boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)',
      },
      '100%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)',
      },
    },
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ 
      flexGrow: 1, 
      height: '100vh', 
      overflow: 'hidden', 
      bgcolor: 'background.default',
      ...styles // Add the keyframe animations
    }}>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>

      <Grid container spacing={0} sx={{ height: '100%' }}>
        <Grid item xs={12} md={4} lg={3} sx={{ height: '100%', overflow: 'hidden' }}>
          <Paper 
            elevation={3} 
            sx={{ 
              height: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              borderRadius: { xs: 0, md: 2 },
              bgcolor: 'background.paper',
              overflow: 'hidden'
            }}
          >
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant={isMobile ? "h5" : "h4"} component="h1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Chat History
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Tooltip title={`Filters ${activeFiltersCount > 0 ? `(${activeFiltersCount})` : ''}`}>
                    <Badge badgeContent={activeFiltersCount} color="primary">
                      <IconButton onClick={() => setFilterDialogOpen(true)}>
                        <Filter size={20} />
                      </IconButton>
                    </Badge>
                  </Tooltip>
                  <Tooltip title="Refresh">
                    <IconButton onClick={handleRefresh} disabled={refreshing}>
                      <RefreshCw className={refreshing ? 'spin' : ''} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search by name, email, or number..."
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <List 
              sx={{ 
                flexGrow: 1,
                overflow: 'auto',
                p: 0,
                m: 0,
                '& .MuiListItem-root': {
                  borderBottom: 1,
                  borderColor: 'divider',
                  p: 2,
                  m: 0,
                  '&:hover': {
                    bgcolor: 'action.hover',
                  }
                }
              }}
            >
              <AnimatePresence mode="sync">
                {chats.map((chat) => (
                  <motion.div
                    key={`${chat.id}-${chat.unique_id}`}
                    layout
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <ListItem
                      alignItems="flex-start"
                      onClick={() => handleChatClick(chat.id)}
                      sx={{
                        cursor: 'pointer',
                        transition: 'background-color 0.2s ease',
                        bgcolor: chat.is_new ? 'action.selected' : 'transparent',
                        position: 'relative',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      }}
                    >
                      <ListItemAvatar>
                        <Tooltip title={chat.is_connected ? 'Connected' : 'Disconnected'} arrow>
                          <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            sx={{
                              '& .MuiBadge-badge': {
                                backgroundColor: chat.is_connected ? 'success.main' : 'error.main',
                                width: 10,
                                height: 10,
                                borderRadius: '50%',
                              },
                            }}
                          >
                            <Avatar sx={{ bgcolor: 'primary.main' }}>
                              <MessageCircle />
                            </Avatar>
                          </Badge>
                        </Tooltip>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1 }}>
                                <Typography component="span" variant="subtitle1" color="text.primary" sx={{ fontWeight: 'bold' }}>
                                  {chat.client__first_name || chat.client__last_name ? (
                                    `${chat.client__first_name} ${chat.client__last_name}`
                                  ) : (
                                    <Box component="span" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                                      Anonymous
                                    </Box>
                                  )}
                                </Typography>
                                {chat.is_processing && (
                                  <Tooltip title="AI is processing..." arrow placement="top">
                                    <CircularProgress
                                      size={16}
                                      sx={{
                                        color: 'primary.main',
                                        animation: 'spin 1s linear infinite',
                                        '@keyframes spin': {
                                          '0%': { transform: 'rotate(0deg)' },
                                          '100%': { transform: 'rotate(360deg)' }
                                        }
                                      }}
                                    />
                                  </Tooltip>
                                )}
                              </Box>
                              <Typography component="span" variant="caption" color="text.secondary">
                                {new Date(chat.last_message).toLocaleString()}
                              </Typography>
                            </Box>
                            
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              <ControllerChip
                                controller={chat.controller}
                                isCurrentUser={chat.is_current_user_controller}
                                humanControl={chat.human_control}
                              />
                              {chat.platform && (
                                <Chip
                                  size="small"
                                  icon={React.createElement(getPlatformConfig(chat.platform).icon, { size: 14 })}
                                  label={getPlatformConfig(chat.platform).label}
                                  sx={{
                                    mr: 1,
                                    mb: 1,
                                    backgroundColor: getPlatformConfig(chat.platform).bgColor,
                                    color: getPlatformConfig(chat.platform).color,
                                    '& .MuiChip-icon': {
                                      color: 'inherit'
                                    }
                                  }}
                                />
                              )}
                              {chat.company__name && (
                                <Chip
                                  size="small"
                                  icon={<Building2 size={14} />}
                                  label={chat.company__name}
                                  sx={{
                                    mr: 1,
                                    mb: 1,
                                    backgroundColor: 'rgba(156, 39, 176, 0.1)',  // Purple background
                                    color: '#9c27b0',  // Purple text
                                    '& .MuiChip-icon': {
                                      color: 'inherit'
                                    }
                                  }}
                                />
                              )}
                              {chat.is_new && (
                                <Chip
                                  label="New"
                                  color="secondary"
                                  size="small"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                              )}
                              {chat.pre_human_control && (
                                <Chip
                                  label="Intervento richiesto"
                                  color="warning"
                                  size="small"
                                  sx={{ mr: 1, mb: 1 }}
                                />
                              )}
                            </Box>
                          </Box>
                        }
                        secondary={
                          <Box 
                            sx={{ 
                              display: 'flex',
                              flexWrap: 'wrap',
                              alignItems: 'center',
                              gap: 1,
                              mt: 1,
                              '& > *': { pointerEvents: 'none' },
                              '& .MuiSwitch-root, & .MuiIconButton-root': { pointerEvents: 'auto' }
                            }}
                          >
                            {!chat.is_processing && 
                             !(chat.platform === 'whatsapp' && !chat.is_connected) && (
                              <Tooltip 
                                title={
                                  chat.human_control 
                                    ? `Switch to AI Control${chat.controller && !chat.is_current_user_controller ? ' (Locked)' : ''}`
                                    : 'Switch to Human Control'
                                } 
                                arrow
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      size="small"
                                      checked={chat.human_control}
                                      onChange={(event) => handleHumanControlToggle(event, chat.id)}
                                      color="primary"
                                      onClick={(e) => e.stopPropagation()}
                                      disabled={!isAdmin && chat.human_control && !chat.is_current_user_controller}
                                    />
                                  }
                                  label={
                                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                                      {chat.human_control ? 'Human Control' : 'AI Control'}
                                    </Typography>
                                  }
                                  sx={{
                                    mr: 1,
                                    opacity: (!isAdmin && chat.human_control && !chat.is_current_user_controller) ? 0.7 : 1
                                  }}
                                />
                              </Tooltip>
                            )}
                            <Box sx={{ display: 'flex', gap: 0.5 }}>
                              <Tooltip title="Copy share link" arrow>
                                <IconButton 
                                  size="small" 
                                  onClick={(e) => handleShareClick(e, chat.unique_id)}
                                >
                                  <Share2 size={16} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Open share link" arrow>
                                <IconButton 
                                  size="small" 
                                  onClick={(e) => handleOpenShareLink(e, chat.unique_id)}
                                >
                                  <ExternalLink size={16} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                        }
                      />
                    </ListItem>
                  </motion.div>
                ))}
              </AnimatePresence>
            </List>

            <Box 
              sx={{ 
                p: 2, 
                borderTop: 1, 
                borderColor: 'divider',
                bgcolor: 'background.paper'
              }}
            >
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
                size={isMobile ? "small" : "medium"}
                disabled={loading || refreshing}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} lg={9} sx={{ height: '100%', overflow: 'auto', display: { xs: 'none', md: 'block' } }}>
          <Box sx={{ height: '100%', p: { xs: 0, md: 2 } }}>
            {selectedChatId ? (
              <ChatHistoryDetail
                chatId={selectedChatId}
                wsConnection={wsRef.current}
                whatsapp={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.whatsapp}
                humanControl={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.human_control}
                isConnected={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.is_connected}
                clientName={`${chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__first_name || ''} ${chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__last_name || ''}`}
                clientEmail={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__email}
                onClose={() => setSelectedChatId(null)}
                onPredictMessage={handlePredictMessage}
                isCurrentUserController={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.is_current_user_controller}
                isAdmin={isAdmin}
              />
            ) : (
              <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                height="100%"
                sx={{ 
                  bgcolor: 'background.paper',
                  borderRadius: { xs: 0, md: 2 },
                  boxShadow: { xs: 0, md: 1 }
                }}
              >
                <Typography variant="h6" color="text.secondary">
                  Select a chat to view details
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      {isMobile && (
        <Dialog
          open={!!selectedChatId}
          onClose={() => setSelectedChatId(null)}
          fullScreen
          TransitionComponent={Fade}
        >
          <DialogContent sx={{ p: 0 }}>
            {selectedChatId && (
              <ChatHistoryDetail
                chatId={selectedChatId}
                wsConnection={wsRef.current}
                whatsapp={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.whatsapp}
                humanControl={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.human_control}
                isConnected={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.is_connected}
                clientName={`${chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__first_name || ''} ${chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__last_name || ''}`}
                clientEmail={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.client__email}
                onClose={() => setSelectedChatId(null)}
                onPredictMessage={handlePredictMessage}
                isCurrentUserController={chats.find(chat => Number(chat.id) === Number(selectedChatId))?.is_current_user_controller}
                isAdmin={isAdmin}
              />
            )}
          </DialogContent>
        </Dialog>
      )}

      <Dialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobile}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            Filters
            <IconButton onClick={() => setFilterDialogOpen(false)} edge="end">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ChatFiltersSection 
            filters={filters} 
            handleFilterChange={handleFilterChange}
            onClose={() => setFilterDialogOpen(false)}
            isMobileDialog={isMobile}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Chats;
